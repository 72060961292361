.markdown {
  line-height: 1.75;
}

.markdown ul,
.markdown ol {
  margin: 0.5rem 0 !important;
  padding-left: 2rem !important;
}

.markdown ul {
  list-style: disc !important;
  list-style-position: outside !important;
}

.markdown ol {
  list-style: decimal !important;
  list-style-position: outside !important;
}

.markdown li {
  display: list-item !important;
  margin: 0.25rem 0;
}

.markdown p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
